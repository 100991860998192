import { useEffect, useMemo, useState } from 'react';
import { ProductCardWrapper } from '../ProductCardWrapper/ProductCardWrapper';
import { FilterWrapper } from '../ProductFilter/FilterWrapper/FilterWrapper';
import { FilterHeader } from '../ProductFilter/FilterHeader/FilterHeader';
import { Typography, LoginPromptBanner } from 'uibook';
import { Breadcrumbs } from '../BreadCrumbs/BreadCrumbs';
import { capitalize } from '@/utils/strings.utils';
import { useCustomerContext } from '@/hooks/useCustomerContext';
import { useConsumerTypeContext } from '@/hooks/useConsumerTypeContext';
import { useHits, useInstantSearch } from 'react-instantsearch';
import { SearchVariantForPLP } from '@/types/productTypes';
import { useProductsContext } from '@/hooks/useProductsContext';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { DEFAULT_LEASE_TERM_LENGTH } from '@/constants/defaultValues';
import { DEFAULT_LEASE_TERM_LENGTH as FEATURE_DEFAULT_LEASE_TERM_LENGTH } from '@/constants/features';
import { getSlugSuffix } from '@/utils/getSlugSuffix';
import { useAppContext } from '@/hooks/useAppContext';
import { HeaderBanner } from '../HeaderBanner/HeaderBanner';
import PreApprovedAmountBanner from '../AddTech/PreApprovedAmountBanner';
import { InArrearsBanner } from '../AddTech/InArrearsBanner';

type ProductsGridProps = {
  dataTestId?: string;
};

export const ProductsGrid = ({ dataTestId }: ProductsGridProps) => {
  const { setModalOpen } = useAppContext();
  const { isUpgrading, deviceUpgrading } = useCustomerContext();
  const { isAddingTech, hasLoggedInCustomer, isMobileApp, formattedPreApprovedAmount, inArrears } =
    useCustomerContext();
  const showPreApprovedAmountBanner = isAddingTech && formattedPreApprovedAmount && !inArrears;
  const { indexUiState } = useInstantSearch();
  const { items } = useHits<SearchVariantForPLP>();
  const { consumerTypePrefixPath } = useConsumerTypeContext();
  const { customerSpecificPricing } = useProductsContext();

  const termLengthFromGrowthBook = useFeatureValue(
    FEATURE_DEFAULT_LEASE_TERM_LENGTH,
    DEFAULT_LEASE_TERM_LENGTH,
  );

  const breadcrumbsAllProducts = useMemo(
    () => ({
      name: 'All products',
      url: consumerTypePrefixPath('/products'),
    }),
    [consumerTypePrefixPath],
  );

  const [breadcrumbItems, setBreadcrumbItems] = useState<
    React.ComponentProps<typeof Breadcrumbs>['items']
  >([breadcrumbsAllProducts]);

  const [defaultLeaseTermLength, setDefaultLeaseTermLength] = useState(DEFAULT_LEASE_TERM_LENGTH);

  useEffect(() => {
    if (termLengthFromGrowthBook && termLengthFromGrowthBook > 0) {
      setDefaultLeaseTermLength(termLengthFromGrowthBook);
    }
  }, [termLengthFromGrowthBook]);

  /**
   * We can't render the full breadcrumbs until we have the data from the search index, but any
   * categories are only applied on the client-side, so we need to update the breadcrumbs after the
   * initial render.
   */
  useEffect(() => {
    const clientSideItems = (() => {
      if (
        indexUiState.refinementList?.category &&
        indexUiState.refinementList.category.length < 1
      ) {
        return [
          {
            ...breadcrumbsAllProducts,
            disabled: true,
          },
        ];
      }

      let categoryName = 'Products';
      const category = indexUiState.refinementList?.category;

      if (category && category.length === 1) {
        categoryName = category[0] === 'tvs' ? 'TVs' : capitalize(category[0] || '');
      }

      return [
        breadcrumbsAllProducts,
        ...(category
          ? [
              {
                name: categoryName,
                url: consumerTypePrefixPath(`/products?category=${category[0]}`),
                disabled: true,
              },
            ]
          : []),
      ];
    })();

    setBreadcrumbItems(clientSideItems);
  }, [consumerTypePrefixPath, indexUiState.refinementList?.category, breadcrumbsAllProducts]);

  /** Check for any customer-specific pricing from the API */
  const itemsWithUpdatedPrices = useMemo(() => {
    /** If there are no results, then just return the items directly from the search index */
    if (!customerSpecificPricing || Object.keys(customerSpecificPricing).length === 0) {
      return items;
    }

    /**
     * If there are prices, update the values in the index in the UI. This won't update the filters
     * though, hopefully something we can iterate on in future.
     */
    return items.map((item) => ({
      ...item,
      ...(customerSpecificPricing[item.variantId] && {
        lowestMonthlyCost: customerSpecificPricing[item.variantId],
      }),
    }));
  }, [items, customerSpecificPricing]);

  /**
   * @experiment Update the URLs for each product card based on the value in the feature flag
   *
   * This can be removed once the experiment is complete
   *
   * @url https://xylofi.atlassian.net/browse/DEV-2879
   */
  const itemsWithUpdatedSlugs = useMemo(
    () =>
      itemsWithUpdatedPrices.map((item) => ({
        ...item,
        variantSlug: item.variantSlug.replace(
          getSlugSuffix(DEFAULT_LEASE_TERM_LENGTH),
          getSlugSuffix(defaultLeaseTermLength),
        ),
      })),
    [defaultLeaseTermLength, itemsWithUpdatedPrices],
  );

  return (
    <>
      <Breadcrumbs items={breadcrumbItems} isLoggedIn={hasLoggedInCustomer} />
      {isUpgrading && deviceUpgrading && (
        <div className="bg-blue-500 px-6 pb-6 pt-2">
          <div className="flex">
            <Typography medium variant="h2" className="text-white">
              Choose an upgrade for your <span className="text-pink-300">{deviceUpgrading}</span>
            </Typography>
          </div>
          <Typography className="mt-2 text-white">
            Showing devices you’re eligible to upgrade to
          </Typography>
        </div>
      )}
      {showPreApprovedAmountBanner && (
        <HeaderBanner onClick={() => setModalOpen('aboutYourLimit')} className="px-6 py-4">
          <PreApprovedAmountBanner formattedAmount={formattedPreApprovedAmount} />
        </HeaderBanner>
      )}
      {inArrears && <InArrearsBanner />}
      {!hasLoggedInCustomer && !isMobileApp && (
        <LoginPromptBanner
          dataTestId="login-prompt"
          accountBaseUrl={process.env.NEXT_PUBLIC_ACCOUNT_BASE_URL}
          classNames="flex"
        />
      )}
      <div className="mx-auto flex w-full max-w-screen-xl flex-wrap justify-between gap-y-6 p-6">
        <FilterHeader />
        <div
          data-testid={dataTestId}
          className="w-full gap-6 lg:grid lg:grid-cols-[1fr_2fr] xl:grid-cols-[1fr_3fr]"
        >
          <div className="hidden lg:block">
            <FilterWrapper />
          </div>
          {!itemsWithUpdatedSlugs.length && (
            <Typography className="text-center">
              No results found. Please update your filters and try again.
            </Typography>
          )}
          <div>
            <div
              className="grid w-full gap-6 md:grid-cols-2 xl:grid-cols-3"
              data-testid="products-grid"
            >
              {itemsWithUpdatedSlugs.map((product, productIndex) => (
                <ProductCardWrapper
                  key={productIndex}
                  product={product}
                  dataTestId={`product-card-${productIndex}`}
                  isPriorityImage={productIndex === 0}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
