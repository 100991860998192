import { ProductCategoriesEnum } from '../types/productCategories';
import { RawVariantForPLP } from '../types/productTypes';
import { getOptionValueBySlug } from './getOptionValueBySlug';

const slugStrings = [
  { string: '-m1-pro-', displayTitle: 'Apple M1 Pro Processor' },
  { string: '-m1-', displayTitle: 'Apple M1 Processor' },
  { string: '-m2-', displayTitle: 'Apple M2 Processor' },
  { string: '-m3-pro-', displayTitle: 'Apple M3 Pro Processor' },
  { string: '-m3-', displayTitle: 'Apple M3 Processor' },
  { string: '-m4-max-', displayTitle: 'Apple M4 Max Processor' },
  { string: '-m4-pro-', displayTitle: 'Apple M4 Pro Processor' },
  { string: '-m4-', displayTitle: 'Apple M4 Processor' },
];

export const createProductSubtitle = (
  slug: string,
  category: ProductCategoriesEnum | string,
  variant?: RawVariantForPLP,
  allOptionTypes?: RawVariantForPLP['optionValues'],
) => {
  switch (category) {
    case ProductCategoriesEnum.PHONE: {
      if (variant) {
        const technology = getOptionValueBySlug(
          variant.optionValues,
          'cellular-network-technology',
        );
        return `SIM-free${technology ? `, ${technology}` : ''}`;
      }
      return 'SIM-free';
    }
    case ProductCategoriesEnum.TABLET: {
      if (variant) {
        const technology = getOptionValueBySlug(allOptionTypes, 'cellular-network-technology');
        return `WIFI${technology && technology !== 'None' ? `, ${technology}` : ''}`;
      }
      return 'WIFI';
    }
    case ProductCategoriesEnum.LAPTOP:
      return (
        slugStrings.find(({ string }) => slug?.includes(string))?.displayTitle || 'Intel Processor'
      );
    default:
      return '';
  }
};
