import { useCurrentRefinements } from 'react-instantsearch';

/**
 * Thin wrapper around `useCurrentRefinements()` from `react-instantsearch` which returns the total
 * number of refinements.
 */
export const useTotalRefinementCount = () => {
  const { items } = useCurrentRefinements();

  const totalRefinementCount = items.reduce((acc, item) => acc + item.refinements.length, 0);

  return { totalRefinementCount };
};
