import {
  ApolloError,
  LazyQueryHookOptions,
  MaybeMasked,
  OperationVariables,
  TypedDocumentNode,
  useLazyQuery,
} from '@apollo/client';
import { useCallback, useRef, useState } from 'react';

type FetchPagedQueryOptions = Partial<{
  pageSize: number;
  returnPartialResults: boolean;
}>;

const defaultOptions: FetchPagedQueryOptions = {
  pageSize: 40,
  returnPartialResults: false,
};

type FetchState<TData, TElement> = {
  executionReference?: object;
  fetchOptions: LazyQueryHookOptions<TData, OperationVariables>;
  outerOnCompleted?: (data: TElement[]) => void;
  outerOnError?: (error: ApolloError) => void;
  data: TElement[];
  startedFetching: boolean;
  fetchedLastPage: boolean;
  error?: ApolloError;
};

type TDefaultPageData<TElement> = {
  nodes?: (TElement | null)[] | null;
  pageInfo: { hasNextPage: boolean; endCursor?: string | null };
};

export function useFetchPagedQuery<
  TData,
  TVariables,
  TElement,
  TPageData extends TDefaultPageData<TElement> = TDefaultPageData<TElement>,
>(
  query: TypedDocumentNode<TData, TVariables>,
  getPageData: (data: MaybeMasked<TData>) => TPageData,
  options?: FetchPagedQueryOptions,
) {
  const { pageSize, returnPartialResults } = {
    ...defaultOptions,
    ...options,
  };

  const [fetchPage] = useLazyQuery(query);

  const executionReference = useRef<object>();
  const [fetchState, setFetchState] = useState<FetchState<TData, TElement>>(newFetchState());

  function newFetchState(): FetchState<TData, TElement> {
    return {
      executionReference: executionReference.current,
      fetchOptions: {},
      data: [],
      startedFetching: false,
      fetchedLastPage: false,
    };
  }

  function isStaleExecutionReference(state: FetchState<TData, TElement>) {
    return state.executionReference !== executionReference.current;
  }

  const onCompleted = useCallback(
    (state: FetchState<TData, TElement>, receivedData: MaybeMasked<TData>) => {
      if (isStaleExecutionReference(state)) {
        // there was another invocation of startFetching(), discard the received data and don't continue this fetching "thread"
        return;
      }

      const newState: FetchState<TData, TElement> = {
        ...state,
      };
      const pageData = getPageData(receivedData);
      if (pageData.nodes?.length) {
        newState.data = newState.data.concat(pageData.nodes!.filter((el) => el != null));
      }
      if (pageData.pageInfo.hasNextPage) {
        setFetchState(newState);
        doFetch(newState, pageData.pageInfo.endCursor);
      } else {
        newState.fetchedLastPage = true;
        setFetchState(newState);
        state.outerOnCompleted?.(newState.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onError = useCallback(
    (state: FetchState<TData, TElement>, error: ApolloError) => {
      if (isStaleExecutionReference(state)) {
        // there was another invocation of startFetching(), discard the received error
        return;
      }

      const newState: FetchState<TData, TElement> = {
        ...state,
        error,
      };
      setFetchState(newState);
      state.outerOnError?.(error);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const doFetch = useCallback(
    (state: FetchState<TData, TElement>, cursor?: string | null) => {
      fetchPage({
        variables: {
          ...state.fetchOptions.variables,
          after: cursor,
        },
      }).then((result) => {
        if (result.error) {
          onError(state, result.error);
        } else if (!result.data) {
          onError(
            state,
            new ApolloError({ errorMessage: 'fetchPage completed without error and without data' }),
          );
        } else {
          onCompleted(state, result.data);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const startFetching = useCallback(
    (queryOptions: {
      variables: Omit<TVariables, 'first' | 'last' | 'before' | 'after'>;
      onCompleted?: (data: TElement[]) => void;
      onError?: (error: ApolloError) => void;
    }) => {
      // invalidate the previous executionReference, so if a caller calls startFetching more than once
      // (with same or different parameters), we just discard the "previous run"
      executionReference.current = new Object();
      const newState: FetchState<TData, TElement> = {
        ...newFetchState(),
        fetchOptions: {
          variables: {
            ...queryOptions.variables,
            first: pageSize,
          },
        },
        outerOnCompleted: queryOptions.onCompleted,
        outerOnError: queryOptions.onError,
        startedFetching: true,
      };
      setFetchState(newState);
      doFetch(newState);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return [
    startFetching,
    {
      loading: !fetchState.error && fetchState.startedFetching && !fetchState.fetchedLastPage,
      data:
        (fetchState.error || !fetchState.fetchedLastPage) && !returnPartialResults
          ? undefined
          : fetchState.data,
      error: fetchState.error,
    },
  ] as const;
}
