import { useEffect, useState } from 'react';
import { useAppContext } from '@/hooks/useAppContext';
import { useClearRefinements } from 'react-instantsearch';
import { Button } from 'uibook';
import { trackClearAllFilters } from '../utils';

type ClearRefinementsProps = {
  className?: string;
};

export const ClearRefinements = ({ className }: ClearRefinementsProps) => {
  const { merchantDomain } = useAppContext();
  const { canRefine, refine } = useClearRefinements();
  const [isClientSide, setIsClientSide] = useState(false);

  /**
   * Not ideal, but as we are statically-generating the page, and the state of each filter is based
   * on the default state of the PLP, which is based on the default state at build time (no filters
   * applied), so it can cause a hydration error if we don't update this client-side when the
   * component is first mounted.
   */
  useEffect(() => {
    setIsClientSide(true);
  }, []);

  if (!canRefine || !isClientSide) {
    return null;
  }

  /** Track the resetting of the refinements */
  const handleClickClearAll = () => {
    refine();
    trackClearAllFilters(merchantDomain);
  };

  return (
    <Button
      data-testid="clear-refinements"
      onClick={handleClickClearAll}
      type="reset"
      variant="text"
      color="blue"
      className={className}
    >
      Clear all
    </Button>
  );
};
