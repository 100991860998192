import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import {
  InstantSearch,
  InstantSearchServerState,
  InstantSearchSSRProvider,
  useInstantSearch,
  useRefinementList,
  useSearchBox,
} from 'react-instantsearch';
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs';
import singletonRouter from 'next/router';
import { SearchOptions, SearchResponses, UiState, Hit } from 'instantsearch.js';
import {
  createProductsInstantSearchAdapter,
  SEARCH_INDEX_PRODUCTS,
  typesenseProductsFields,
} from 'uibook/utils/search';
import { useConsumerTypeContext } from '@/hooks/useConsumerTypeContext';
import { SearchVariantForPLP } from '@/types/productTypes';
import { getPricingFieldsFromLowestMonthlyCost } from '@/utils/getProductsCardDataModel';
import { useProductsContext } from '@/hooks/useProductsContext';
import { useCustomerContext } from '@/hooks/useCustomerContext';

export const productsInstantSearchAdapter = createProductsInstantSearchAdapter({
  apiKey: process.env.NEXT_PUBLIC_TYPESENSE_SEARCH_KEY!,
  host: process.env.NEXT_PUBLIC_TYPESENSE_NODE!,
});

/** Facets which are stored in the URL */
const urlFacets: string[] = [
  typesenseProductsFields.category,
  typesenseProductsFields.make,
  typesenseProductsFields.model,
  typesenseProductsFields.condition,
];

/**
 * Calculates the count of each price group in the given array of search hits.
 *
 * @param {SearchVariantForPLP[]} hits - The array of search hits to process.
 * @param {typeof typesenseProductsFields.priceGroup
 *   | typeof typesenseProductsFields.priceGroupBeforeTax} field
 *   - The field to use for grouping the prices.
 *
 * @returns {Record<number, number>} An object where the keys are price groups and the values are
 *   the counts of each price group.
 */
const getPriceGroupCount = (
  hits: SearchVariantForPLP[],
  field:
    | typeof typesenseProductsFields.priceGroup
    | typeof typesenseProductsFields.priceGroupBeforeTax,
) => {
  return hits.reduce((acc: Record<number, number>, hit) => {
    const priceGroup = hit[field];
    /**
     * `priceGroup` can be `0`, which would be falsy, so `if (priceGroup) {}` does not work here, so
     * instead just assert that it's a number.
     */
    if (typeof priceGroup === 'number') {
      if (priceGroup in acc) {
        acc[priceGroup] += 1;
      } else {
        acc[priceGroup] = 1;
      }
    }
    return acc;
  }, {});
};

type SearchProviderProps = PropsWithChildren<{
  serverState?: InstantSearchServerState;
}>;

export const SearchProvider = ({ serverState, children }: SearchProviderProps) => {
  const { consumerTypePrefixPath } = useConsumerTypeContext();
  const { preApprovedAmount } = useCustomerContext();
  const { customerSpecificPricing } = useProductsContext();

  /**
   * For customer-specific pricing, we need to intercept the response from Typesense and update the
   * pricing in the responses, before they are added to the UI. This is so the prices are also
   * applied to the facets.
   */
  const searchClientWithPricingOverride: typeof productsInstantSearchAdapter.searchClient =
    useMemo(() => {
      /** Extend the `TypesenseInstantSearchAdapter` instance and update the `search` method */
      class SearchClientWithPricingOverride extends productsInstantSearchAdapter.searchClient
        .constructor {
        async search(requests: Array<{ indexName: string; params: SearchOptions }>) {
          const hasCustomerSpecificPricing =
            customerSpecificPricing && Object.keys(customerSpecificPricing).length > 0;

          /** Send the requests to the normal `TypesenseInstantSearchAdapter` search client */
          const response: SearchResponses<SearchVariantForPLP> =
            await productsInstantSearchAdapter.searchClient.search(requests);

          /** If we don't have any customer-specific pricing, just return the response as normal */
          if (!hasCustomerSpecificPricing) {
            return response;
          }

          /**
           * Check if the request params contains a `numericFilters` field which matches the
           * customers pre-approval amount. If it does, that means the user has the `pre-approval`
           * facet enabled.
           */
          const isFilteringByPreApprovedAmount =
            !!preApprovedAmount &&
            requests.some((request) => {
              const numericFilters = request.params.numericFilters;

              return (
                numericFilters &&
                (numericFilters.includes(
                  `${typesenseProductsFields.monthlyprice}<=${preApprovedAmount}`,
                ) ||
                  numericFilters.includes(
                    `${typesenseProductsFields.monthlypriceBeforeTax}<=${preApprovedAmount}`,
                  ))
              );
            });

          /**
           * When sorting, the `indexName` in the request matches the ones in the `SortMenu`
           * component, so check if the `indexName` is one of those.
           *
           * If the `indexName` is the default (`products`), then we don't need to do anything as
           * we're sorting by `Recommended`.
           */
          const isSortingByHighestPriceFirst = requests.some(
            (request) =>
              request.indexName ===
              `${SEARCH_INDEX_PRODUCTS}/sort/${typesenseProductsFields.monthlyprice}:desc`,
          );

          const isSortingByLowestPriceFirst = requests.some(
            (request) =>
              request.indexName ===
              `${SEARCH_INDEX_PRODUCTS}/sort/${typesenseProductsFields.monthlyprice}:asc`,
          );

          /**
           * If we do have customer-specific pricing, iterate through the results and apply our
           * custom pricing to each of the `hits`, which are the individual items.
           */
          const results = response.results.map((result) => {
            // @ts-expect-error - `hits` does exist, but the `SearchResponses` type uses a weird union type
            const hits: SearchVariantForPLP[] = result.hits
              .map((hit: Hit<SearchVariantForPLP>) => {
                if (!customerSpecificPricing[hit.variantId]) {
                  return hit;
                }

                /**
                 * Get the pricing fields using the same method we use to format them when adding
                 * them to the Typesense index.
                 */
                const pricingFields = getPricingFieldsFromLowestMonthlyCost(
                  customerSpecificPricing[hit.variantId],
                );

                return {
                  ...hit,
                  ...pricingFields,
                };
              })
              .filter((hit: Hit<SearchVariantForPLP>) => {
                /**
                 * Filter the results based on if the user is filtering based on the pre-approval
                 * facet. If they are not, return the results without filtering.
                 */
                if (!isFilteringByPreApprovedAmount) {
                  return true;
                }

                /**
                 * However if the user _is_ filtering by the pre-approved amount, remove any hits
                 * which are above their pre-approval amount.
                 */
                return (
                  hit.lowestMonthlyCost?.valueAfterTax &&
                  hit.lowestMonthlyCost?.valueAfterTax <= preApprovedAmount
                );
              })
              /**
               * Typesense returns the hits based on the order of the values in the collection for
               * the sort-order, but with risk-based pricing, some of the hits might have changed
               * more than others. If that is the case, re-order the items based on the values and
               * sort order, before returning the hits.
               */
              .sort((a: Hit<SearchVariantForPLP>, b: Hit<SearchVariantForPLP>) => {
                if (
                  a[typesenseProductsFields.monthlyprice] &&
                  b[typesenseProductsFields.monthlyprice]
                ) {
                  if (isSortingByHighestPriceFirst) {
                    return (
                      b[typesenseProductsFields.monthlyprice]! -
                      a[typesenseProductsFields.monthlyprice]!
                    );
                  }

                  if (isSortingByLowestPriceFirst) {
                    return (
                      a[typesenseProductsFields.monthlyprice]! -
                      b[typesenseProductsFields.monthlyprice]!
                    );
                  }
                }
              });

            return {
              ...result,
              hits,
              facets: {
                // @ts-expect-error - `result.facets` does exist, but the `SearchResponses` type uses a weird union type
                ...result.facets,
                /**
                 * If there is risk pricing, then the number of each items in each price group might
                 * change, so we need to recalculate the price groups.
                 */
                [typesenseProductsFields.priceGroup]: getPriceGroupCount(
                  hits,
                  typesenseProductsFields.priceGroup,
                ),
                [typesenseProductsFields.priceGroupBeforeTax]: getPriceGroupCount(
                  hits,
                  typesenseProductsFields.priceGroupBeforeTax,
                ),
              },
            };
          });

          /** Return the response with the modified results */
          return {
            ...response,
            results,
          };
        }
      }

      const searchClient = new SearchClientWithPricingOverride();
      return searchClient;
    }, [customerSpecificPricing, preApprovedAmount]);

  return (
    <InstantSearchSSRProvider {...serverState}>
      <InstantSearch
        indexName={SEARCH_INDEX_PRODUCTS}
        searchClient={searchClientWithPricingOverride}
        future={{
          preserveSharedStateOnUnmount: true,
        }}
        routing={{
          router: createInstantSearchRouterNext({
            singletonRouter,
            serverUrl: `${process.env.NEXT_PUBLIC_PRODUCTS_BASE_URL}${consumerTypePrefixPath('/products')}`,
            routerOptions: {
              cleanUrlOnDispose: false,
              /**
               * This is used to update the URL when a search facet changes. We override the default
               * instantsearch behaviour so that the URL structure is backwards-compatible with the
               * existing PLP logic.
               *
               * Essentially, this adds/removes the `urlFacets` fields to/from the URL.
               *
               * @url https://github.com/algolia/instantsearch/blob/master/packages/instantsearch.js/src/lib/routers/history.ts#L314
               */
              createURL: ({ qsModule, routeState, location }) => {
                const existingUrlSearchParams = qsModule.parse(location.search.slice(1));

                const refinementList = routeState[SEARCH_INDEX_PRODUCTS].refinementList;
                if (refinementList) {
                  /** `pick` */
                  const facetsForUrl = Object.fromEntries(
                    urlFacets
                      .filter((key) => key in refinementList)
                      .map((key) => [key, refinementList[key]]),
                  );

                  if (Object.keys(facetsForUrl).length > 0) {
                    const updatedUrlSearchParams = qsModule.stringify(
                      {
                        ...existingUrlSearchParams,
                        ...facetsForUrl,
                      },
                      { arrayFormat: 'repeat' },
                    );
                    return `${location.origin}${location.pathname}?${updatedUrlSearchParams}`;
                  }
                }

                if (!routeState[SEARCH_INDEX_PRODUCTS]?.refinementList) {
                  const reducedUrlSearchParams = Object.fromEntries(
                    Object.entries(existingUrlSearchParams).filter(
                      ([key]) => !urlFacets.includes(key),
                    ),
                  );
                  const updatedUrlSearchParams = qsModule.stringify(reducedUrlSearchParams, {
                    arrayFormat: 'repeat',
                  });
                  return `${location.origin}${location.pathname}${updatedUrlSearchParams ? `?${updatedUrlSearchParams}` : ''}`;
                }

                return location.toString();
              },
              /**
               * This is used to read the state of the search from the URL. We override the default
               * instantsearch behaviour so that the URL structure is backwards-compatible with the
               * existing PLP logic.
               *
               * Essentially, this reads the `urlFacets` fields from the URL, and applies them to
               * the search.
               *
               * @url https://github.com/algolia/instantsearch/blob/master/packages/instantsearch.js/src/lib/routers/history.ts#L314
               */
              parseURL: ({ qsModule, location }) => {
                const parsed = qsModule.parse(location.search.slice(1), {
                  arrayLimit: 0,
                });

                const refinementList = Object.fromEntries(
                  Object.entries(parsed)
                    .filter(([key]) => urlFacets.includes(key))
                    .map(([key, value]) => {
                      return [key, Array.isArray(value) ? value : [value]];
                    }),
                );

                return {
                  [SEARCH_INDEX_PRODUCTS]: {
                    refinementList,
                    ...(parsed.q ? { query: parsed.q } : {}),
                  },
                } as unknown as UiState;
              },
            },
          }),
        }}
      >
        <VirtualSearchBox />
        {children}
      </InstantSearch>
    </InstantSearchSSRProvider>
  );
};

/**
 * Within the PLP, we don't current have a search box, but we need to attach a search widget so that
 * we can read the `q` param from the URL, which is why `useSearchBox()` is added below. This is
 * used to update the search state when the user navigates to the PLP with a search query.
 *
 * Although this component does not return anything, it's required to be rendered within the
 * `InstantSearch` component to work correctly.
 *
 * Also, adding `useRefinementList` resolves an issue where:
 *
 * 1. User is a logged-in customer
 * 2. User clicks the "Upgrade" CTA in the Account app
 * 3. User is redirected to the PLP with a URL, such as `/products?category=phones`
 * 4. The search state is not updated to reflect the `category` param, and the user is redirected to
 *    `/products`
 *
 * This can be removed if we move to server-side rendering instead of static generation.
 *
 * @url https://www.algolia.com/doc/guides/building-search-ui/widgets/customize-an-existing-widget/react/?client=VirtualRefinementList.js#building-a-virtual-widget-with-hooks
 * @url https://www.algolia.com/doc/api-reference/widgets/search-box/react/#hook
 */
function VirtualSearchBox() {
  const { refresh } = useInstantSearch();
  useSearchBox();

  /**
   * We need to pass an attribute here, so just use the `typesenseProductsFields.category` value.
   * It's likely we can pass any of our facets, but `typesenseProductsFields.category` is probably
   * the most used, so it makes the most sense to prioritise this.
   */
  useRefinementList({
    attribute: typesenseProductsFields.category,
  });

  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    if (!hasMounted) {
      setHasMounted(true);

      /**
       * This is used to fix the issue above (the one with 4 steps), essentially it's used to sync
       * the client-side state with the the URL state. It doesn't seem to create any extra requests
       * to the Search API.
       */
      refresh();
    }
  }, [hasMounted, refresh]);

  return null;
}
