import { BaseModal } from 'uibook';
import { FilterWrapper } from '../FilterWrapper/FilterWrapper';
import { useAppContext } from '@/hooks/useAppContext';
import { track } from '@/integrations/segment/segmentTracking';
import { FilterModalButtons } from '../FilterModalButtons/FilterModalButtons';

export const FilterWrapperModal = () => {
  const { modalOpen, setModalOpen, merchantDomain } = useAppContext();

  if (modalOpen !== 'filters') {
    return null;
  }

  const closeModal = () => {
    setModalOpen(null);
    track('View Filter And Sort Results Clicked', {
      customer_type: 'new',
      merchant: merchantDomain,
    });
  };

  return (
    <>
      <BaseModal
        modalOpen
        closeModal={closeModal}
        offsetTop={64}
        mobileWidth="calc(100% - 40px)"
        desktopWidth="calc(100% - 40px)"
        maxWidth={500}
      >
        <FilterWrapper />
      </BaseModal>
      <FilterModalButtons closeModal={closeModal} />
    </>
  );
};
