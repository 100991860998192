import { FilterProductCount } from '../FilterProductCount/FilterProductCount';
import { NativeSelect, Typography } from 'uibook';
import { useSortBy } from 'react-instantsearch';
import { trackSortOptionSelected } from '../utils';
import { SEARCH_INDEX_PRODUCTS, typesenseProductsFields } from 'uibook/utils/search';

const sortByOptions = [
  { label: 'Recommended', value: SEARCH_INDEX_PRODUCTS },
  {
    label: 'Highest Price',
    value: `${SEARCH_INDEX_PRODUCTS}/sort/${typesenseProductsFields.monthlyprice}:desc`,
  },
  {
    label: 'Lowest Price',
    value: `${SEARCH_INDEX_PRODUCTS}/sort/${typesenseProductsFields.monthlyprice}:asc`,
  },
];

export const SortMenu = () => {
  const { currentRefinement, options, refine } = useSortBy({
    items: sortByOptions,
  });

  /** Update the refinements and track the changes */
  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    refine(event.target.value);

    const option = sortByOptions.find((option) => option.value === event.target.value);
    if (option) {
      trackSortOptionSelected(option.label);
    }
  };

  return (
    <div
      className="flex w-full flex-col gap-3 lg:flex-row lg:items-center lg:gap-4"
      data-testid="sort-menu"
    >
      <FilterProductCount className="mb-3 lg:hidden" />

      <Typography bold>Sort by</Typography>
      <NativeSelect
        data-testid="sort-menu-select"
        value={currentRefinement}
        onChange={handleOnChange}
        /** Temporary font-size override to match the existing PLP font-size */
        className="type-body2 lg:w-64"
      >
        {options.map((option) => (
          <option key={option.label} value={option.value}>
            {option.label}
          </option>
        ))}
      </NativeSelect>
    </div>
  );
};
