import { cn, Typography } from 'uibook';
import { useStats } from 'react-instantsearch';

type FilterProductCountProps = {
  className?: string;
};

export const FilterProductCount = ({ className }: FilterProductCountProps) => {
  const { nbHits } = useStats();

  return (
    <Typography
      data-testid="filter-product-count"
      variant="body2"
      className={cn('text-charcoal-400', className)}
    >
      {nbHits} product{nbHits !== 1 && 's'}
    </Typography>
  );
};
