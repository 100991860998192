import { FormCheckbox } from 'uibook';
import { useInstantSearch, useRange } from 'react-instantsearch';
import { typesenseProductsFields } from 'uibook/utils/search';
import { useConsumerTypeContext } from '@/hooks/useConsumerTypeContext';
import { useFilterByPreApprovedAmount } from '../hooks/useFilterByPreApprovedAmount';

type FilterTypePreApprovedProps = {
  preApprovedAmount: number;
};

export const FilterTypePreApproved = ({ preApprovedAmount }: FilterTypePreApprovedProps) => {
  const { consumerIsBusiness } = useConsumerTypeContext();
  const { indexUiState, results } = useInstantSearch();
  const { filterByPreApprovedAmount } = useFilterByPreApprovedAmount();

  const attribute = consumerIsBusiness
    ? typesenseProductsFields.monthlypriceBeforeTax
    : typesenseProductsFields.monthlyprice;

  const itemCount = results.hits.filter((hit) => hit.monthlyprice <= preApprovedAmount).length;

  /**
   * Required to connect this attribute to the search UI. Without this, the `react-instantsearch`
   * library won't apply these changes to the UI.
   */
  useRange({
    attribute: attribute,
  });

  const handleToggleCheckbox = () => {
    filterByPreApprovedAmount('toggle');
  };

  return (
    <FormCheckbox
      dataTestId={`checkbox-${attribute}-pre-approved`}
      checked={!!indexUiState.range?.[attribute]}
      label={`Pre-approved (${itemCount})`}
      onChange={handleToggleCheckbox}
      size={20}
    />
  );
};
