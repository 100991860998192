import { track } from '@/integrations/segment/segmentTracking';

type TrackToggledFiltersProps = {
  category: string[] | undefined;
  attribute: string;
  value: string;
  isRefined: boolean;
  merchant: string;
};

export const trackToggledFilters = ({
  category,
  attribute,
  value,
  isRefined,
  merchant,
}: TrackToggledFiltersProps) => {
  const categoryFiltered = category?.length && category.length === 1 ? category[0] : 'All';

  track(`Filter ${isRefined ? 'Unselected' : 'Selected'}`, {
    category_filtered: categoryFiltered,
    customer_type: 'new',
    filter_type: attribute,
    filter_value: value,
    merchant,
  });
};

export const trackClearAllFilters = (merchant: string) => {
  track('Filters Cleared', {
    customer_type: 'new',
    merchant,
  });
};

export const trackSortOptionSelected = (sortType: string) => {
  track('Sort Selected', {
    customer_type: 'new',
    sort_type: sortType,
  });
};
