import Head from 'next/head';
import { DEFAULT_STRUCTURED_DATA } from './structuredData.constants';

type StructuredDataProductsProps = {
  dataTestId?: string;
};

export const StructuredDataProducts = ({ dataTestId }: StructuredDataProductsProps) => {
  return (
    <Head>
      <script type="application/ld+json" data-testid={dataTestId}>
        {JSON.stringify(DEFAULT_STRUCTURED_DATA)}
      </script>
    </Head>
  );
};
