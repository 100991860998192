import { ProductsProvider } from '@/hooks/useProductsContext';
import { useAppContext } from '@/hooks/useAppContext';
import { ProductsGrid } from '@/components/ProductsGrid/ProductsGrid';
import { FilterWrapperModal } from '@/components/ProductFilter/FilterWrapperModal/FilterWrapperModal';
import { useCustomerContext } from '@/hooks/useCustomerContext';
import { AboutYourLimitModal } from '@/components/AddTech';
import PageMeta from '@/components/PageMeta';
import ProductsWrapper from '@/components/ProductsWrapper';
import IntercomWrapper from '@/components/IntercomWrapper/IntercomWrapper';
import { ASSETS_IMAGES_BASE_URL } from 'uibook';
import { useBodyClassName } from '@/hooks/useBodyClassName';
import { InstantSearchServerState } from 'react-instantsearch';
import { SearchProvider } from '@/providers/SearchProvider';
import { StructuredDataProducts } from '@/components/StructuredData/StructuredDataProducts';

type ProductsContainerProps = {
  productVariantIds: React.ComponentProps<typeof ProductsProvider>['productVariantIds'];
  typesenseServerState?: InstantSearchServerState;
};

export const ProductsContainer = ({
  productVariantIds,
  typesenseServerState,
}: ProductsContainerProps) => {
  const { modalOpen } = useAppContext();
  const { formattedPreApprovedAmount } = useCustomerContext();

  useBodyClassName('page-plp');

  return (
    <>
      <PageMeta
        title="Products | Raylo"
        metaTitle="Choose from our range of brand new phones and refurbished phones"
        metaDescription="We offer both brand new phones and refurbished SIM-free phones"
        metaImage={`${ASSETS_IMAGES_BASE_URL}/raylo-og-image.avif`}
      />
      <StructuredDataProducts />

      <IntercomWrapper>
        <ProductsWrapper context="products">
          <ProductsProvider productVariantIds={productVariantIds}>
            <SearchProvider serverState={typesenseServerState}>
              <ProductsGrid />
              {modalOpen === 'filters' && <FilterWrapperModal />}
              <AboutYourLimitModal formattedAmount={formattedPreApprovedAmount} />
            </SearchProvider>
          </ProductsProvider>
        </ProductsWrapper>
      </IntercomWrapper>
    </>
  );
};
