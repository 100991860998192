import { Button, cn } from 'uibook';
import { useClearRefinements } from 'react-instantsearch';
import { useTotalRefinementCount } from '../hooks/useTotalRefinementCount';

export const FilterModalButtons = ({ closeModal }: { closeModal: () => void }) => {
  const { totalRefinementCount } = useTotalRefinementCount();
  const { refine } = useClearRefinements();

  const isFiltering = totalRefinementCount > 0;

  const handleClickClearAll = (event: React.MouseEvent) => {
    event.preventDefault();
    refine();
    closeModal();
  };

  return (
    <div
      data-testid="filter-results-button-wrapper"
      className={cn(
        'fixed bottom-0 z-[10001] flex w-full transform items-center justify-center gap-6 bg-white p-6 shadow-[0_-0.625rem_0.625rem_-0.3125rem_rgba(0,0,0,0.04),0_-1.25rem_1.5625rem_-0.3125rem_rgba(0,0,0,0.1)] transition-transform duration-500 lg:hidden',
        {
          'translate-y-0': isFiltering,
          'translate-y-full': !isFiltering,
        },
      )}
    >
      <Button
        size="large"
        variant="outlined"
        className="w-full max-w-60"
        onClick={handleClickClearAll}
        data-testid="clear-all-button"
      >
        Clear All
      </Button>
      <Button
        size="large"
        variant="filled"
        className="w-full max-w-60"
        onClick={closeModal}
        data-testid="filter-results-button"
      >
        {isFiltering ? `Apply (${totalRefinementCount})` : 'Apply'}
      </Button>
    </div>
  );
};
