import { useEffect, useState } from 'react';
import { FormCheckbox, Typography, FilterLabel, cn } from 'uibook';
import { FilterTypeProps, FilterIcon } from './types';
import { useClearRefinements, useInstantSearch, useRefinementList } from 'react-instantsearch';
import { StyledFilterBottomFade } from './styles';
import { trackToggledFilters } from '../utils';
import { useAppContext } from '@/hooks/useAppContext';
import { typesenseProductsFields } from 'uibook/utils/search';
import { FilterTypePreApproved } from './FilterTypePreApproved';

export const FilterType = ({ facet, preApprovalBanner }: FilterTypeProps) => {
  const [isClientSide, setIsClientSide] = useState(false);

  const { merchantDomain } = useAppContext();
  const { indexUiState } = useInstantSearch();

  const { refine: clearRefinements } = useClearRefinements({
    /**
     * `model` should always be cleared here, as `model` is only rendered if a `category` is
     * selected.
     *
     * This fixes an edge case where:
     *
     * 1. User selects a `category`
     * 2. User selects a `model`
     * 3. User deselects the `category`
     * 4. `model` is still selected, but not visible in the UI so it looks like there are less results
     *    than there should be
     */
    includedAttributes: [facet.attribute, typesenseProductsFields.model],
  });

  const { items, refine } = useRefinementList({
    ...facet.searchConfig,
    attribute: facet.attribute,
    /**
     * The default number of items for the facet to show is `10`, but for our use-case, we want a
     * much higher limit, so increase this to `999`. This is so we can show all the items in the
     * `model` facet.
     *
     * @url https://www.algolia.com/doc/api-reference/widgets/refinement-list/react/#hook-params-limit
     */
    limit: 999,
  });

  /**
   * Not ideal, but as we are statically-generating the page, and the state of each filter is based
   * on the default state of the PLP, which is based on the default state at build time (no filters
   * applied), so it can cause a hydration error if we don't update this client-side when the
   * component is first mounted.
   */
  useEffect(() => {
    setIsClientSide(true);
  }, []);

  if (facet.uiConfig?.isVisible && !facet.uiConfig?.isVisible(indexUiState) && isClientSide) {
    return null;
  }

  const handleClickRefinement = (itemValue: string, isRefined: boolean) => {
    refine(itemValue);
    trackToggledFilters({
      category: indexUiState.refinementList?.category,
      attribute: facet.attribute,
      value: itemValue,
      isRefined: isRefined,
      merchant: merchantDomain,
    });
  };

  const anyItemsRefined = items.some((item) => item.isRefined);

  const showOnlyPreApprovalInfo = !!facet.uiConfig?.showOnlyPreApprovalInfo;

  const formattedItems = (() => {
    /**
     * When the customer is in add-tech mode, the price filters don't work, as the response from
     * Typesense does not take into account the risk-based pricing. The quick fix for now is the
     * hide the price filters when the customer is in ADD_TECH mode, however we still show the
     * "pre-approval amount" filter.
     *
     * When the customer is in `UPGRADE` mode, we hide it completely, using the `isVisible` function
     * configured in `FilterWrapper`.
     *
     * @url https://xylofi.atlassian.net/browse/DEV-2925
     */
    if (showOnlyPreApprovalInfo) {
      return [];
    }
    /**
     * `useRefinementList` doesn't let you sort by numerical values, but we want to be able to do
     * that, so if `facet.uiConfig.sortByNumericalValue` is true then sort by numerical values,
     * lowest to highest
     */
    if (facet.uiConfig?.sortByNumericalValue) {
      return [...items].sort((a, b) => parseInt(a.value) - parseInt(b.value));
    }
    /** If not, return the items in the default order */
    return items;
  })();

  const showFade =
    facet.uiConfig?.showFadeWhenMoreThan &&
    facet.type !== 'labels' &&
    formattedItems.length > facet.uiConfig.showFadeWhenMoreThan;

  return (
    <div className="flex flex-col gap-3" data-testid={`filter-type-${facet.attribute}`}>
      <Typography bold data-testid={`filter-type-${facet.attribute}-title`}>
        {facet.title}
      </Typography>

      {showOnlyPreApprovalInfo && preApprovalBanner}

      <div
        className={cn({
          relative: showFade,
        })}
      >
        <div
          className={cn('flex', {
            'flex-row flex-wrap gap-3': facet.type === 'labels',
            'flex-col gap-2': facet.type !== 'labels',
            'max-h-[26rem] overflow-y-auto pb-9': showFade,
          })}
        >
          {facet.type !== 'labels' && !showOnlyPreApprovalInfo && (
            <FormCheckbox
              dataTestId={`checkbox-${facet.attribute}-all`}
              label="All"
              onChange={() => clearRefinements()}
              checked={isClientSide && !anyItemsRefined}
              disabled={isClientSide && !anyItemsRefined}
              size={20}
            />
          )}

          {facet.uiConfig?.showOnlyPreApprovalInfo &&
            !!facet.uiConfig.preApprovedAmount &&
            facet.uiConfig.preApprovedAmount > 0 && (
              <FilterTypePreApproved preApprovedAmount={facet.uiConfig.preApprovedAmount} />
            )}

          {formattedItems.map((item, i) => {
            if (facet.type === 'labels') {
              return (
                <FilterLabel
                  dataTestId={`label-${facet.attribute}-${item.value}`}
                  key={`${item.value}-${i}`}
                  copy={item.label}
                  isSelected={isClientSide && item.isRefined}
                  onSelect={() => handleClickRefinement(item.value, item.isRefined)}
                  textTransform="uppercase"
                />
              );
            }

            const checkboxLabel = facet.uiConfig?.labelMap
              ? facet.uiConfig?.labelMap[item.label]
              : item.label;

            return (
              <FormCheckbox
                key={`${item.value}-${i}`}
                dataTestId={`checkbox-${facet.attribute}-${item.value}`}
                checked={isClientSide && item.isRefined}
                label={`${checkboxLabel} (${item.count})`}
                onChange={() => handleClickRefinement(item.value, item.isRefined)}
                icon={facet.uiConfig?.renderWithIcon ? (checkboxLabel as FilterIcon) : undefined}
                size={20}
              />
            );
          })}
        </div>
        {showFade && <StyledFilterBottomFade className="absolute bottom-0 left-0 h-9 w-full" />}
      </div>
    </div>
  );
};
