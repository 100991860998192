import { useEffect, useMemo, useState } from 'react';
import constate from 'constate';
import { useFetchCustomerSpecificPricing } from '@/hooks/useFetchCustomerSpecificPricing';
import { useCustomerContext } from './useCustomerContext';
import { captureException } from '@sentry/core';
import { CostSummaryPriceRecord } from '@/types/productTypes';

type UseProductsProps = {
  /**
   * `productVariantIds` are the variant IDs used in the Typesense collection, which are added at
   * build time. It makes more sense to add these to the context statically, instead of using the
   * direct results from Typesense, so that there are less requests. It should also handle a
   * potential case where:
   *
   * 1. User is logged in
   * 2. The `fetchCustomerSpecificPricing` query fires, and is still running
   * 3. The user starts filtering
   * 4. The Typesense results change
   * 5. Because the results change, the `fetchCustomerSpecificPricing` query is refired
   *
   * This would create a lot of expensive pricing requests to the API, which could be avoided by
   * only searching for the variants which are available at build-time, which should match all of
   * the initial Typesense results (before any filtering occurs).
   */
  productVariantIds: string[];
};

const useProducts = ({ productVariantIds }: UseProductsProps) => {
  const { hasLoggedInCustomer, checkoutToken, isCustomerDataReady } = useCustomerContext();

  const [shouldDisplayPrices, setShouldDisplayPrices] = useState(false);

  const [
    fetchCustomerSpecificPricing,
    { data: customerSpecificPricingData, loading: customerSpecificPricingDataLoading },
  ] = useFetchCustomerSpecificPricing();

  useEffect(() => {
    if (
      hasLoggedInCustomer &&
      productVariantIds.length &&
      !customerSpecificPricingData &&
      isCustomerDataReady &&
      !customerSpecificPricingDataLoading
    ) {
      fetchCustomerSpecificPricing({
        variables: {
          ids: productVariantIds,
          checkoutToken: checkoutToken ?? null,
          merchantId: process.env.NEXT_PUBLIC_RAYLO_MERCHANT_ID,
        },
        onError: (error) => {
          console.error('PRICING ERROR PRODUCTS', error);
          captureException(error);
        },
      });
    }

    if (!hasLoggedInCustomer) {
      setShouldDisplayPrices(true);
    }
  }, [
    hasLoggedInCustomer,
    fetchCustomerSpecificPricing,
    isCustomerDataReady,
    customerSpecificPricingData,
    checkoutToken,
    customerSpecificPricingDataLoading,
    productVariantIds,
  ]);

  useEffect(() => {
    if (customerSpecificPricingData?.length) {
      setShouldDisplayPrices(true);
    }
  }, [customerSpecificPricingData]);

  const customerSpecificPricing = useMemo(() => {
    if (customerSpecificPricingData) {
      const indexedVariantCosts = customerSpecificPricingData.reduce(
        (acc: Record<string, CostSummaryPriceRecord>, variant) => {
          if (variant.lowestCostSummary) {
            acc[variant.id] = {
              valueAfterTax: variant.lowestCostSummary.recurring.totalAmount.valueAfterTax.value,
              valueBeforeTax: variant.lowestCostSummary.recurring.totalAmount.valueBeforeTax.value,
            };
          }
          return acc;
        },
        {},
      );

      if (Object.keys(indexedVariantCosts).length) {
        return indexedVariantCosts;
      }
    }

    return null;
  }, [customerSpecificPricingData]);

  return {
    shouldDisplayPrices,
    customerSpecificPricing,
  };
};

const [ProductsProvider, useProductsContext] = constate(useProducts);
export { ProductsProvider, useProductsContext };
