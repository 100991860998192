import { useEffect, useState } from 'react';
import { cn, Typography } from 'uibook';
import { useTotalRefinementCount } from '../hooks/useTotalRefinementCount';

type FilterTotalRefinementsProps = {
  className?: string;
};

export const FilterTotalRefinements = ({ className }: FilterTotalRefinementsProps) => {
  const { totalRefinementCount } = useTotalRefinementCount();
  const [isClientSide, setIsClientSide] = useState(false);

  /**
   * Not ideal, but as we are statically-generating the page, and the state of each filter is
   * based on the default state of the PLP, which is based on the default state at build time (no
   * filters applied), so it can cause a hydration error if we don't update this client-side when
   * the component is first mounted.
   */
  useEffect(() => {
    setIsClientSide(true);
  }, []);

  if (totalRefinementCount === 0 || !isClientSide) {
    return null;
  }

  return (
    <Typography
      data-testid="filter-total-refinements"
      variant="fineprint"
      bold
      element="span"
      /**
       * The `!` in `!leading-none` is used to override the line-height set by the `Typography`
       * component, which in-turn is set by the className `type-fineprint`. If the `line-height`
       * property is removed from the `type-fineprint` className in future, then the `!` from
       * `!leading-none` can be removed from here as well.
       */
      className={cn(
        'flex h-5 w-5 items-center justify-center rounded-full bg-blue-500 !leading-none text-white',
        className,
      )}
    >
      {totalRefinementCount}
    </Typography>
  );
};
