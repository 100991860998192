import { Icon, Typography } from 'uibook';
import { useAppContext } from '@/hooks/useAppContext';
import { track } from '@/integrations/segment/segmentTracking';
import { FilterTotalRefinements } from '../FilterTotalRefinements/FilterTotalRefinements';

export const FilterSortTrigger = () => {
  const { setModalOpen, merchantDomain } = useAppContext();

  const openModal = () => {
    setModalOpen('filters');
    track('Filter and Sort Clicked', {
      customer_type: 'new',
      merchant: merchantDomain,
    });
  };

  return (
    <button
      onClick={openModal}
      data-testid="mobile-filter-trigger"
      className="outline-charcoal-100 size-12 shadow-7 sticky right-6 top-[6.875rem] z-[99] ml-auto flex items-center justify-end gap-2 rounded-full bg-white p-3 outline outline-1 hover:outline-2 min-[360px]:w-[9.25rem] min-[360px]:px-4 min-[360px]:py-2.5 lg:hidden"
    >
      <span className="size-6 block flex-shrink-0">
        <Icon name="Filter" $height={24} $width={24} />
      </span>
      <Typography bold className="hidden text-blue-500 min-[360px]:block">
        Filter/Sort
      </Typography>
      <FilterTotalRefinements className="absolute -right-1 -top-1" />
    </button>
  );
};
