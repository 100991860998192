import { CUSTOMER_SPECIFIC_LOWEST_COST_SUMMARY_PAGED } from '@/graphql/operations/customerSpecificPricing.graphql';
import { useFetchPagedQuery } from './useFetchPagedQuery';
import {
  ProductsRepoCustomerSpecificLowestCostSummaryPagedQuery,
  ProductsRepoCustomerSpecificLowestCostSummaryPagedQueryVariables,
} from '@/graphql/generated/graphql';

export const FETCH_CUSTOMER_PRICING_PAGE_SIZE = 40;

export type CustomerSpecificPricingVariantData = Exclude<
  Exclude<
    ProductsRepoCustomerSpecificLowestCostSummaryPagedQuery['variantsConnection']['nodes'],
    null | undefined
  >[number],
  null | undefined
>;
export type CustomerSpecificPricingData = { variants: CustomerSpecificPricingVariantData[] };

export const useFetchCustomerSpecificPricing = () =>
  useFetchPagedQuery<
    ProductsRepoCustomerSpecificLowestCostSummaryPagedQuery,
    ProductsRepoCustomerSpecificLowestCostSummaryPagedQueryVariables,
    CustomerSpecificPricingVariantData
  >(CUSTOMER_SPECIFIC_LOWEST_COST_SUMMARY_PAGED, (data) => data.variantsConnection, {
    pageSize: FETCH_CUSTOMER_PRICING_PAGE_SIZE,
  });
